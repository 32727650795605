/* eslint-disable max-classes-per-file */
import { ExportFormat } from '@app/interfaces/generated/pim.types';
import { ISCountry } from '@app/interfaces/microservices/shared-interfaces/global';
import { IGlobalFormsStatus, IGlobalFormsStatusCheckboxLabels } from '@app/interfaces/uimodels/global-form-status.interface';
import { IForm } from '@app/interfaces/uimodels/global/IForm';
import { UUID } from '@app/interfaces/uimodels/global/UUID';
import { ECmsNames } from '@app/interfaces/uimodels/localization/ECmsNames';
import { Moment } from 'moment';
import { IFormSelect } from './IFormSelect';
import { EMaterialIcons } from './materialIcons';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import PlaceResult = google.maps.places.PlaceResult;

// --- consts -------------------------------

export const IGItemBooleanI18nLabels = ['labeltrue', 'labelfalse'];
export const dummyPassword7x = 'xxxxxxx';

// --- enums -------------------------------

export enum EGLegType {
	train = 'train',
	truck = 'truck',
	shunt = 'shunt',
	depot = 'depot'
}

export enum EGWayPointType {
	terminal = 'terminal',
	city = 'city',
	depot = 'depot'
}

export enum EGCurrency {
	EUR = 'EUR'
}

export enum ELabelColors {
	normal = 'normal',
	normalblue = 'normalblue',
	warning = 'warning',
	happy = 'happy',
	attention = 'attention'
}

export enum EFormThemes {
	white = 'modifier-white',
	grey = 'modifier-grey',
	blue = 'modifier-blue',
	whiteDarkBorder = 'modifier-white dark-border',
	dark = 'modifier-dark'
}

export enum EInputsTypes {
	number = 'number',
	text = 'text',
	time = 'time',
	password = 'password',
	tel = 'tel',
	email = 'email'
}

export enum EGRoutesPath {
	versions = 'versions',
	freshdesklogin = 'freshdesklogin',

	relations = 'relations',
	relationscreateoffer = 'createoffer',

	relationsearch = 'relationsearch',
	login = 'login',
	searchresults = 'searchresults',
	checkout = 'checkout',
	relationssuccessoffercreated = 'successoffercreated',

	registration = 'registration',
	registrationcompanydata = 'companydata',
	registrationsubscriptiondata = 'subscriptiondata',
	registrationcontactdata = 'contactdata',
	registrationpaymentdata = 'paymentdata',
	registrationthankyou = 'registrationthankyou',
	registrationsummary = 'summary',
	passwordset = 'password/set',
	passwordreset = 'password/reset',
	carrier = 'carrier',
	carrierbasedata = 'basedata',
	carrierbookingsexport = 'bookingsexport',
	carrierevaluation = 'evaluation',
	carriermessages = 'messages',
	carrierinformation = 'information',

	profile = 'profile',
	profileedit = 'profile/edit',
	profileinvoices = 'profile/invoices',
	profilesummary = 'profile/summary',
	profiledifferentaddress = 'profile/different-address',

	carrierstart = 'start',
	carriertransports = 'transports',
	carriertransportdetails = 'transports/detail',
	bookingseries = 'bookingseries',
	carriertransportchange = 'transports/change',
	operator = 'operator',
	operatorbasisdata = 'basisdata',
	operatorbookingsexport = 'bookingsexport',
	operatorevaluation = 'evaluation',
	operatorinformation = 'information',
	operatormessages = 'carriermessages',
	prices = 'prices',

	operatorrelations = 'relations',
	operatorrelationsdetails = 'relations/detail',
	operatorrelationsurcharges = 'relations/surcharges',
	operatorfreelegsurcharges = 'freelegs/surcharges',
	operatorstart = 'start',
	operatoroffers = 'offers',
	operatorofferdetails = 'offers/detail',
	operatorofferchange = 'offers/change',
	generalterms = 'generalterms',
	exceptions = 'exceptions',
	companyactivation = 'administration/companyactivation',
	superuserdashboard = 'superuser/dashboard',
	organization = 'organization',
	notFound = 'not-found',
	forbidden = 'forbidden',
	linkedIn = 'https://www.linkedin.com/company/modility',
	instagram = 'https://www.instagram.com/modility.gmbh'
}

export enum EGRoutesRelRoutePath {
	versions = 'versions',
	freshdesklogin = 'freshdesklogin',
	login = 'login',

	relations = 'relations',
	relationscreateoffer = 'relationscreateoffer',
	relationssuccessoffercreated = 'relationssuccessoffercreated',

	relationsearch = 'relationsearch',
	searchresults = 'searchresults',
	checkout = 'checkout',

	registration = 'registration',
	registrationcompanydata = 'registrationcompanydata',
	registrationcontactdata = 'registrationcontactdata',
	registrationsubscriptiondata = 'registrationsubscriptiondata',
	registrationthankyou = 'registrationthankyou',
	registrationsummary = 'registrationsummary',
	passwordset = 'passwordset',
	passwordreset = 'passwordreset',
	carrier = 'carrier',
	carrierbasedata = 'carrierbasedata',
	carrierbookingsexport = 'carrierbookingsexport',
	carrierevaluation = 'carrierevaluation',
	carrierinformation = 'carrierinformation',

	profile = 'profile',
	profileedit = 'profileedit',
	profileinvoices = 'profileinvoices',
	profilesummary = 'profilesummary',
	profiledifferentaddress = 'profiledifferentaddress',

	carrierstart = 'carrierstart',
	carriertransports = 'carriertransports',
	carriertransportdetails = 'carriertransportdetails',
	carrierbookingseries = 'carrierbookingseries',
	carriertransportchange = 'carriertransportchange',
	operator = 'operator',
	operatorbasisdata = 'operatorbasisdata',
	operatorbookingsexport = 'operatorbookingsexport',
	operatorevaluation = 'operatorevaluation',
	operatorinformation = 'operatorinformation',
	operatormessages = 'operatormessages',
	operatorprices = 'operatorprices',

	operatorrelations = 'operatorrelations',
	operatorrelationsdetails = 'operatorrelationsdetails',
	operatorbookingseries = 'operatorbookingseries',
	operatorrelationsurcharges = 'operatorrelationsurcharges',
	operatorfreelegsurcharges = 'operatorfreelegsurcharges',
	operatorstart = 'operatorstart',
	operatoroffers = 'operatoroffers',
	operatorofferdetails = 'operatorofferdetails',
	operatorofferchange = 'operatorofferchange',
	generalterms = 'generalterms',
	exceptions = 'exceptions',
	companyactivation = 'companyactivation',
	superuser = 'superuser',
	organization = 'organization',
	notFound = 'not-found',
	forbidden = 'forbidden'
}

export enum EGRoutesCssThemes {
	themeApp = 'theme_app',
	Tlandingpage = 'theme_landingpage'
}

export enum EState {
	global = 'global',
	relations = 'relations',
	analytics = 'analytics',
	operator = 'operator',
	carrier = 'carrier'
}

export enum ERegisteredTaskIcons {
	warning = EMaterialIcons.warning,
	arrowsLeftRight = EMaterialIcons.sync_alt,
	assignment = EMaterialIcons.assignment,
	attention = EMaterialIcons.feedback
}

// TODO refactor into one single Locale class/interface
export enum EGLanguage {
	en = 'EN',
	de = 'DE'
}

export enum EGLanguageMoment {
	EN = 'en-us',
	DE = 'de'
}

// see https://tools.ietf.org/html/bcp47
export enum EGLanguageBCP47 {
	EN = 'en-US',
	DE = 'de-DE'
}

export enum Iso639alpha2 {
	de = 'de',
	en = 'en'
}

// maps backend currencyCode values to frontend representations
export enum ELanguageCurrencies {
	EUR = '€'
}

export enum EGNewsletterLayerType {
	register = 'Register',
}

export enum EGProfileSummaryActionEvents {
	change = 'change',
	delete = 'delete'
}

export enum EGBookingExportContentItemLegType {
	firstLeg = 'firstLeg',
	lastLeg = 'lastLeg',
	mainLeg = 'mainLeg'
}

export enum EGBookingStatusValue {
	'notExistent' = 'notExistent',
	'existentNotAccepted' = 'existentNotAccepted',
	'existentAccepted' = 'existentAccepted'
}

export enum EGTransportChainType {
	TT = 'tt',
	TC = 'tc',
	CT = 'ct',
	CC = 'cc',
	TCM = 'tcm',
	CMT = 'cmt',
	CCM = 'ccm',
	CMC = 'cmc',
	CMCM = 'cmcm',
	UNKNOWN = 'unknown'
}

export enum EGLegBookingStatus {
	accepted = 'Accepted',
	declined = 'Declined',
	canceled = 'Canceled',
	pending = 'Pending',
	expired = 'Expired',
	unknown = 'Unknown'
}

export enum EFilterChipActions {
	add = 'ADD',
	remove = 'remove'
}

export class IGInterpolationsValue {
	public constructor(public value: string | number) {
	}
}

export interface IGIdName {
	id?: UUID | string;
	name?: string;
	nameLocalizationVariables?: IGInterpolationsValue;
	label?: string; //TODO refactor remove label
}

export class IGI18next<T = Record<string, string | number>> {
	public constructor(
		public label: string,
		public interpolations?: T
	) {
	}
}

export interface IGItemI18next<T> {
	label: string;
	value: IGI18next<T>;
	id?: string;
}

export interface IGPriceValueAndCurrency {
	value: string | number;
	currency?: string;
}

export class PriceValueAndCurrency {
	public constructor(
		public value = 0,
		public currency = 'EUR') {
	}
}

export interface IGItemWhereValueIs<T> extends Record<string, unknown> {
	id?: string;
	value: T;
	label: string;
}

export interface IGItemBoolean extends Record<string, string | boolean | typeof IGItemBooleanI18nLabels | undefined | unknown> {
	labeltrue?: string;
	labelfalse?: string;
	value?: boolean;
	i18nLabels?: typeof IGItemBooleanI18nLabels;
}

export interface IGimage {
	src: string;
	alt?: string;
	title?: string;
}

export interface IGFreeWeightClass {
	id: UUID;
	maxWeight: number;
	label?: string;
	sublabel?: string;
}

export interface IGIdAndNameWhereIdIs<T> {
	id?: T;
	name?: string;
	nameLocalizationVariables?: IGInterpolationsValue;
	code?: string;
}

export interface IGIdAndNameWhereCodeIs<T> {
	id: string;
	name: string;
	nameLocalizationVariables?: IGInterpolationsValue;
	active?: boolean;
	code: T;
}

export interface IGLink {
	label: any;
	url: EGRoutesPath | string;
	id?: EGRoutesRelRoutePath | string;
}

export interface IGLinkAction {
	text?: string;
	icon?: string;
	hint?: string;
}

export interface IGPoint {
	label: string;
	valueCity?: string;
	valueStreetName?: string;
	valueHouseNumber?: string;
	valueTerminal?: string;
	valuePostalcode?: string;
	legNotAvailable?: boolean;
	deviatingTerminal?: boolean;
	leg: boolean;
}

export enum EGPageType {
	landingpage = 'landingpage',
	app = 'app'
}

export interface IGRoutesData {
	theme: EGRoutesCssThemes;
	relRoutePath: EGRoutesRelRoutePath;
	dashboard?: boolean;
	type: EGPageType;
	cmsname: ECmsNames | null;
	removeHeader?: boolean;
	removeFooter?: boolean;
}

export interface IGDistance {
	label?: string;
	unit?: string;
	value: number;
}

export interface IGIcon {
	name: string | EMaterialIcons | ERegisteredTaskIcons;
	color?: string | ELabelColors;
}

export interface IGDateTime {
	// todo: remove
	label?: string;
	date?: string;
	time?: string;
	timeUnit?: string;
	weekday?: number;
	dayNames?: string[];
	timestamp?: number;
}

export interface IGTimeDate {
	timeLabel?: string;
	timeValue?: IGI18next<{ value: string }>;
	dateLabel?: string;
	dateValue?: IGI18next<{ value: string }>;
	timestamp?: number;
}

export interface IGDuration {
	label: string;
	valueDay: IGItemWhereValueIs<number>;
	valueHours: IGItemWhereValueIs<number>;
	valueMinutes: IGItemWhereValueIs<number>;
}

export interface IGPrice {
	label?: string;
	labelType?: string;
	noValue?: string;
	value?: number;
	valueCurrency?: EGCurrency;
	individual?: boolean;
}

export class IGInterpolationsLabelValue {
	public interpolations: IGInterpolationsValue;

	public constructor(
		public label: string,
		interpolationsValue?: string | number
	) {
		this.interpolations = new IGInterpolationsValue(interpolationsValue || '');
	}

}

export interface IGInput {
	label?: string;
	sublabel?: string;
	value: string | number;
	appearance: ELabelColors | string;
	disabled?: boolean;
}

export interface IGTooltip {
	value: string;
}

export interface IGGeoPoint {
	lat: number;
	lon: number;
}

// --- forms ------

export interface IGFormInputValidate<A extends string | number | boolean | IGI18next, B = { value: A; originalValue?: A }> extends IForm {
	content: {
		id?: string | UUID;
		label: string;
		sublabel?: string;
		placeholder?: string;
	} & B;
	validate?: IGItemBoolean;
	// disabled?: boolean;
	// required?: boolean;
}

export interface IGFormDatepickerValidate<A>
	extends IGFormInputValidate<'',
	{
		dateValue?: IGI18next<{ value: string | undefined }>;
		timeValue?: IGI18next<{ value: string | undefined }>;
		value?: A;
	}> {
	appearance?: ELabelColors | string;
}

export interface IGGoogleAddressAutocomplete extends IGlobalFormsStatus {
	label: string;
	placeholder?: string;
	selectibleOptions?: AutocompletePrediction[];
	selectedValue?: AutocompletePrediction;
	placeDetails?: Partial<PlaceResult>;
	validate?: IGItemBoolean;
	disabled?: boolean;
}

export enum EGGooglePlaceSearchType {
	address = 'address',
	establishment = 'establishment',
	regions = '(regions)',
	geocode = 'geocode'
}

export type IGFormRadioValidate<T> = IFormSelect<T>

export interface IGFormCheckboxValidate extends Record<string, unknown> {
	contentlabels?: IGlobalFormsStatusCheckboxLabels;
	validatelabels?: IGlobalFormsStatusCheckboxLabels;
	value: boolean;
	validate: boolean;
}

// --- forms ------

// --- app interfaces ---

export interface IGAppstatus {
	theme: EGRoutesCssThemes;
}

// ===== state management via forms utils =====
/**
 * @deprecated returns a function preconfigured with an object and its field it shoud write a new value to when called with this value;
 */
export interface IGUpdateStateFromForm {
	updateAndDoSmth: (
		doSmthFunction: (formPath: Record<string, unknown>) => void | Promise<void>,
		state?: Record<string, unknown>
	) => <T extends Record<string, unknown>, U extends keyof T>(statePath?: T, statePathFinalField?: U) => (formOutputValue: unknown) => void;

	updateState: <T extends Record<string, unknown>, U extends keyof T>(statePath: T, statePathFinalField: U) => (formOutputValue: unknown) => void;
}

/**
 * @deprecated
 */
export type IGCountry = ISCountry;

export interface IGBookingStatus {
	label: string;
	value: EGBookingStatusValue | null;
	noteHTML?: IGI18next<{ value: string }> | null;
}

export interface IGTransportChain {
	headline?: string;
	labelTerminal: string;
	labelLeg: string;
	type: EGTransportChainType;
	deviatingSearchType?: boolean;
}

export interface IGFromToDatePicker {
	fromForm: IGFormDatepickerValidate<Moment>;
	toForm: IGFormDatepickerValidate<Moment>;
}

export interface IGExportBookingsDialog {
	header: string;
	subline: string;
	selectibleExportFormatOptions: IGIdAndNameWhereCodeIs<ExportFormat>[];
	actions: {
		labelCancel: string;
		labelExport: string;
	};
}

export enum EGWaypointOrLeg {
	waypoint = 'waypoint',
	leg = 'leg'
}

export interface IAnalyticsOfferMetaInfo {
	name: string;
	price: string;
	currencyCode: string;
	id: UUID;
	timeOfDeparture: string; //
	freeCapacity: string;
	variant: string;
	brand: string;
	category: string;
}
